<!--
 * @Description  : 
 * @Author       : BigBigger
 * @Date         : 2021-11-12 09:43:05
 * @LastEditTime : 2023-01-11 15:35:46
 * @LastEditors  : BigBigger
-->
<template>
  <div class="info-table">
    <div class="scroll-area" v-if="orderInfo">
      <!-- 课程信息 -->
      <div class="part">
        <div class="title">课程信息</div>
        <div class="item">
          <div class="label">课程名称</div>
          <div class="content">
            {{ orderInfo.loCourseInfo.courseGroupName }}
          </div>
        </div>
        <div
          class="item classInfo"
          v-if="orderInfo.loCourseInfo.whetheHaveClassManager === 1"
        >
          <div class="label">班级信息</div>
          <div class="content">
            <span>{{ flightNameList }}</span>
          </div>
        </div>
        <!-- <div class="item">
            <div class="label">上课形式</div>
            <div class="content">{{orderInfo.loCourseInfo.categoryName}}{{orderInfo.loCourseInfo.classWay}}</div>
          </div> -->
        <div class="item">
          <div class="label">上课地址</div>
          <div class="content">
            {{ orderInfo.loCourseInfo.classLocation }}
          </div>
        </div>
        <div
          class="item"
          v-if="orderInfo.loCourseInfo.whetheHaveClassManager !== 1"
        >
          <div class="label">时间</div>
          <div class="content">
            {{ orderInfo.loCourseInfo.classTime }}
          </div>
        </div>
        <!-- <div class="item">
            <div class="label">主讲</div>
            <div class="content">
              <span v-for="(titem, tindex) of orderInfo.loCourseInfo.teachers" :key="tindex">
                {{titem.teacherName}}
                <template v-if="tindex !== orderInfo.loCourseInfo.teachers.length - 1">、</template>
              </span>
            </div>
          </div> -->
        <div class="item">
          <div class="label">备注</div>
          <div class="content">
            {{ orderInfo.loCourseInfo.subtitle }}
          </div>
        </div>
        <div class="item">
          <div class="label">价格</div>
          <div class="content">
            <span class="font-color"
              >￥{{
                orderInfo.loCourseInfo.throughPay === 1 &&
                orderInfo.throughPay === 1
                  ? orderInfo.loCourseInfo.showPrice
                  : orderInfo.loCourseInfo.currentPrice
              }}</span
            >
          </div>
        </div>
      </div>
      <!-- 收货地址 -->
      <div
        class="part"
        v-if="addressInfo && orderInfo.loCourseInfo.hasShopAddress"
      >
        <div class="title">收货地址</div>
        <div class="item">
          <div class="label">收货人</div>
          <div class="content">{{ addressInfo.name }}</div>
        </div>
        <div class="item">
          <div class="label">联系方式</div>
          <div class="content">{{ addressInfo.phone }}</div>
        </div>
        <div class="item">
          <div class="label">收货地址</div>
          <div class="content">
            <span>
              {{ addressInfo.provinceName }}
              {{ addressInfo.cityName }}
              {{ addressInfo.regionName }}
              {{ addressInfo.detailAddress }}
            </span>
          </div>
        </div>
      </div>
      <div
        class="part"
        v-if="orderInfo.loJboInfo && orderInfo.loCourseInfo.interviewFlag === 1"
      >
        <div class="title">岗位选择</div>
        <div class="item">
          <div class="label">单位名称</div>
          <div class="content">
            {{ orderInfo.loJboInfo.employer }}
          </div>
        </div>
        <div class="item">
          <div class="label">部门名称</div>
          <div class="content">
            {{ orderInfo.loJboInfo.departmentName }}
          </div>
        </div>
        <div class="item">
          <div class="label">职位名称</div>
          <div class="content">
            {{ orderInfo.loJboInfo.positionName }}
          </div>
        </div>
        <div class="item">
          <div class="label">职位代码</div>
          <div class="content">{{ orderInfo.loJboInfo.jobCode }}</div>
        </div>
        <div class="item">
          <div class="label">招聘人数</div>
          <div class="content">
            {{ orderInfo.loJboInfo.numberCandidates }}
          </div>
        </div>
      </div>
      <div v-if="!trialClass">
        <div class="part" v-if="orderInfo.loUserInfo">
          <div class="title">个人信息</div>
          <div class="item">
            <div class="label">真实姓名</div>
            <div class="content">
              {{ orderInfo.loUserInfo.realName }}
            </div>
          </div>
          <div class="item">
            <div class="label">性别</div>
            <div class="content">
              {{ orderInfo.loUserInfo.gender | sexFilter }}
            </div>
          </div>
          <div class="item">
            <div class="label">电话</div>
            <div class="content">{{ orderInfo.loUserInfo.phone }}</div>
          </div>
          <template v-if="orderInfo.loCourseInfo.interviewFlag === 1">
            <div class="item">
              <div class="label">新/老学员</div>
              <div class="content">
                {{ orderInfo.loUserInfo.newStudent | studentFilter }}
              </div>
            </div>
            <div class="item">
              <div class="label">笔试总分</div>
              <div class="content">
                {{ orderInfo.loUserInfo.totalScore }}
              </div>
            </div>
            <div class="item">
              <div class="label">笔试排名</div>
              <div class="content">
                {{ orderInfo.loUserInfo.ranking }}
              </div>
            </div>
            <!-- <div class="label">本岗位最低进面分数</div> -->
            <!-- <div class="content">{{orderInfo.loUserInfo.lowestScore}}</div> -->
            <div class="item">
              <div class="label">身份证号</div>
              <div class="content">
                {{ orderInfo.loUserInfo.idCard }}
              </div>
            </div>
            <div class="item">
              <div class="label">准考证号</div>
              <div class="content">
                {{ orderInfo.loUserInfo.admissionTicket }}
              </div>
            </div>
            <div v-if="isShowLowestScore" class="item">
              <div class="label">成绩单截图凭证</div>
              <div class="content">
                <img
                  :src="orderInfo.loUserInfo.lowestScore"
                  alt=""
                  style="width: 1.5rem; height: 1.5rem"
                />
              </div>
            </div>

            <div class="item">
              <div class="label">学院</div>
              <div class="content">
                {{ orderInfo.loUserInfo.profession }}
              </div>
            </div>
            <!-- <div class="item">
                <div class="label">地址</div>
                <div class="content">{{orderInfo.loUserInfo.address}}</div>
              </div> -->
          </template>
          <div class="item">
            <div class="label">应届/往届</div>
            <div class="content">
              {{ orderInfo.loUserInfo.freshStudent | freshStudentFilter }}
            </div>
          </div>
          <div class="item">
            <div class="label">学校</div>
            <div class="content">{{ orderInfo.loUserInfo.school }}</div>
          </div>
          <!-- <div class="item">
            <div class="label">院系</div>
            <div class="content">{{ orderInfo.loUserInfo.field02 }}</div>
          </div> -->
          <!-- 暂时取消备注区别 -->
          <!-- <div class="item" v-if="isZJ"> -->
          <div class="item">
            <div class="label">备注</div>
            <div class="content">{{ orderInfo.loUserInfo.remark }}</div>
          </div>
          <!-- <div class="item" v-else>
              <div class="label">查分密码</div>
              <div class="content">{{orderInfo.loUserInfo.remark}}</div>
            </div> -->
        </div>
      </div>
      <div
        class="part"
        v-if="
          orderInfo.loCourseInfo &&
          orderInfo.loCourseInfo.interviewFlag === 3 &&
          orderInfo.loCourseInfo.oldStudentsPreferential === 1
        "
      >
        <div class="title">优惠信息</div>
        <div class="item">
          <div class="label">是否老学员</div>
          <div class="content">
            {{
              orderInfo.loUserInfo.studentsPreferential === 1
                ? "是(参与老学员优惠)"
                : "否"
            }}
          </div>
        </div>
        <div
          class="item"
          v-if="orderInfo.loUserInfo.studentsPreferential === 1"
        >
          <div class="label">老学员证明</div>
          <div class="content">
            <img
              :src="orderInfo.loUserInfo.field01"
              alt=""
              style="width: 1.5rem; height: 1.5rem"
            />
          </div>
        </div>
      </div>
      <!-- 面试课程不显示支付方式 -->
      <div
        class="part"
        v-if="
          !orderInfo.loJboInfo && orderInfo.loCourseInfo.interviewFlag !== 1
        "
      >
        <div class="title">支付形式</div>
        <div class="item" v-if="orderInfo.loCourseInfo.throughPay === 1">
          <div class="label">缴费方式</div>
          <div class="content">
            <span>{{ orderInfo.throughPay | throughPayFilter }}</span>
          </div>
        </div>
        <div class="item">
          <div class="label">支付方式</div>
          <div class="content">
            <span>{{ payType | paywayFilter }}</span>
            <span class="font-color"
              >￥{{
                +payType === 1 ? getPrice : orderInfo.loCourseInfo.deposit
              }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="btn-area">
      <p class="tip">请确认所填写信息是否有误，若无误请点击确认</p>
      <p
        class="tip"
        v-if="
          isZJ &&
          orderInfo.loCourseInfo &&
          orderInfo.loCourseInfo.interviewFlag !== 3
        "
      >
        {{
          orderInfo.loCourseInfo.payPrompt ||
          "注：定金支付成功后，职位1：1被保护，放弃培训者不退定金"
        }}
      </p>
      <div class="btn-box">
        <div class="btn" @click="onClose('cancel')">取消</div>
        <div class="btn red" @click="onClose('confirm')">确认</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  PAYWAY,
  SEX,
  STUDENT,
  FRESHSTUDENT,
  THROUGH_PAY,
} from "@/config/config";
import { getFlightName } from "@/api/api";
export default {
  name: "InfoTable",
  data() {
    return {
      isZJ: process.env.VUE_APP_AREA === "zj" ? true : false,
      flightIds: [],
      flightNameList: null,
    };
  },
  computed: {
    getPrice() {
      if (!this.orderInfo.loCourseInfo) return "";
      if (
        this.orderInfo.loCourseInfo.interviewFlag === 3 &&
        this.orderInfo.loCourseInfo.payWay === 1 &&
        this.orderInfo.loUserInfo.studentsPreferential === 1 &&
        this.orderInfo.loUserInfo.field01
      ) {
        return (
          this.orderInfo.loCourseInfo.afterOldStudentsPrice +
          ` (老学员优惠 ${this.orderInfo.loCourseInfo.oldStudentsPrice})`
        );
        // return subtract(numeric(this.courseInfo.currentPrice, 'BigNumber'), numeric(this.courseInfo.oldStudentsPrice, 'BigNumber')) + ` (老学员优惠 ${this.courseInfo.oldStudentsPrice})`;
      }
      return this.orderInfo.loCourseInfo.currentPrice;
    },
  },
  props: {
    orderInfo: {
      default: () => {
        return {};
      },
      type: Object,
    },
    addressInfo: {
      default: () => {
        return;
      },
      type: Object,
    },
    payType: {
      default: 0,
    },
    trialClass: {
      type: Boolean,
      default: false,
    },
    isShowLowestScore: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    let orderInfo = this.$store.state.orderInfo || null;
    if (orderInfo) {
      this.flightIds = orderInfo.classInfo;
      if (orderInfo.loCourseInfo.whetheHaveClassManager === 1) {
        this.getFlightName();
      }
    }
  },
  methods: {
    onClose(val) {
      this.$emit("onClose", val);
    },
    async getFlightName() {
      let params = {
        flightIds: this.flightIds,
      };
      let response = await getFlightName(params);
      if (+response.returnCode !== 10001) {
        this.$toast(response.returnMsg);
        return false;
      }
      let str = response.data;
      this.flightNameList = str.replace(/,/g, "；");
    },
  },
  filters: {
    paywayFilter(val) {
      return PAYWAY[+val];
    },
    sexFilter(val) {
      return SEX[+val];
    },
    studentFilter(val) {
      return STUDENT[+val];
    },
    freshStudentFilter(val) {
      return FRESHSTUDENT[+val];
    },
    throughPayFilter(val) {
      return THROUGH_PAY[+val] || "";
    },
  },
};
</script>

<style lang="less" scoped>
.info-table {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0.6rem;
  .scroll-area {
    // flex-grow: 1;
    // flex-shrink: 1;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    // width: calc(100% - 0.6rem);
    border-radius: 0.1rem;
    background: #fff;
    margin-top: 0.43rem;
    padding: 0.4rem;
    // overflow-scrolling:touch;
    .part {
      .title {
        color: #ff3f47;
        font-size: 16px;
        font-weight: bold;
        color: #ff3f47;
        margin-top: 0.3rem;
      }
      .item {
        border-bottom: 1px solid #f2f2f5;
        // min-height: 0.94rem;
        padding: 0.33rem 0;
        font-size: 13px;
        line-height: 0.26rem;
        display: flex;
        .label {
          flex-grow: 0;
          flex-shrink: 0;
          width: 1.45rem;
          display: flex;
          align-items: center;
          color: #5b6b73;
          line-height: 0.26rem;
        }
        .content {
          flex-grow: 1;
          flex-shrink: 1;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          color: #333;
          line-height: 0.3rem;
        }
      }
    }
  }
  .btn-area {
    // height: 2rem;
    flex-grow: 0;
    flex-shrink: 0;
    padding-bottom: 0.43rem;
    margin-top: 0.43rem;
    .tip {
      font-size: 13px;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      line-height: 0.4rem;
    }
    .btn-box {
      display: flex;
      justify-content: center;
      margin-top: 0.4rem;
      .btn {
        width: 2.52rem;
        height: 0.76rem;
        line-height: 0.76rem;
        text-align: center;
        margin: 0 0.1rem;
        font-size: 14px;
        border-radius: 0.1rem;
        background: #fff;
        color: #999999;
      }
      .red {
        background: #ff3f47;
        color: #fff;
      }
    }
  }
}
.font-color {
  color: #fb4a3e;
  margin-left: 5px;
}
.innerbox {
  height: 100%;
  overflow: auto;
}
</style>
